import axios from "axios";

export async function getInstagramMedia() {
    return axios.get("https://glambackend-dot-stage-glamwave-dd09b.appspot.com/instagram/media")
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
            return []
        })
}