import React, {useContext, useEffect, useState} from 'react';
import {
    Box,
    Button,
    Card,
    Grid,
    Header,
    Image,
    InfiniteScroll, Layer,
    Page,
    PageContent,
    ResponsiveContext,
    Spinner, Video,
} from 'grommet';
import {getInstagramMedia} from '../Api/igApi'
import {Instagram} from "grommet-icons";

const PlaceholderItem = ({size, item}) => (
    <Card height={size} width={size} key={item} justify="center" align="center" background="light-1"
          gap="medium">
        <Spinner key={`spinner-${item}`} size={size}/>
    </Card>
);

const ImageItem = ({size, item}) => (
    <Card key={`media-${item.id}`} height={size} width={size} justify="center" align="center" background="light-1"
          gap="medium">
        { item.media_type === "IMAGE" || item.media_type === "CAROUSEL_ALBUM" ?
            <Image key={`image-${item.id}`} src={item.media_url} fit="cover"/> :
            <Video
                key={`video-${item.id}`}
                controls={{
                    position: false,
                    items: ["fullScreen"]
                }}
                mute={true}
                autoPlay={true}
            >
                <source key={`source-${item.id}`} src={item.media_url} type={"video/mp4"}/>
            </Video>
        }
    </Card>
);

const Main = () => {
    const size = useContext(ResponsiveContext);
    const [media, setMedia] = useState([]);
    const cards = Array(1)
        .fill()
        .map((_, i) => (<PlaceholderItem size={size} key={i} item={i}/>));

    useEffect(() => {
        if (media.length === 0) {
            getInstagramMedia().then((res) => {
                if (res.data.length > 0) {
                    setMedia(res.data.map((item) => <ImageItem key={`image-item-${item.id}`} size={size} item={item}/>));
                }
            })
        }
    }, [media])
    return (
        <Page kind="narrow">
            <Header>
                <Box
                    width="medium"
                    height="small"
                    animation="fadeIn"
                >
                    <Image src="glamwave-01.png" fit="contain" as="img"/>
                </Box>
            </Header>
            <PageContent alignContent="center" align="center">
                <Grid
                    columns={{
                        count: 1,
                        size: 'auto',
                    }}
                    gap="small"
                    fill={true}
                    align="center"
                    alignSelf="center"
                    justify="center"
                    justifyContent="center"
                >
                    <InfiniteScroll items={media.length === 0 ? cards : media} step={5}>
                        {(item) => (
                            item
                        )}
                    </InfiniteScroll>
                    <Layer
                        position="bottom-right"
                        modal={false}
                        margin={{vertical: 'medium', horizontal: 'small'}}
                        responsive={false}
                        plain
                    >
                        <Button size={size === "xlarge" ? "large" : size} label="Follow Us!" icon={<Instagram/>}
                                      onClick={(event) => {
                                          window.location = "https://www.instagram.com/glamwave_/"
                                      }}
                        hoverIndicator
                        primary
                        />
                    </Layer>
                </Grid>
            </PageContent>
        </Page>
    )
}

export default Main;