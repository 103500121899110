import React from 'react';
import {createRoot} from 'react-dom/client';
import {BrowserRouter} from "react-router-dom";
import {Grommet} from 'grommet';
import './index.css';
import * as serviceWorker from './serviceWorker';
import Main from "./App/Main";
import {theme} from "./Grommet/theme";

let root = createRoot(document.getElementById('root'));
root.render(
    <Grommet theme={theme} full={true}>
    <BrowserRouter>
        <Main/>
    </BrowserRouter>
    </Grommet>
);
serviceWorker.unregister();